import React, { useContext } from "react"
import StyledImage from "../StyledImage"
import * as St from "../Styled"
import { getConfigValue } from "../../constants/props"
import ConfirmDialog from "../Modal/ConfirmDialog"
import { AppContext } from "../../contexts/AppContext"
import useModal from "../Modal/useModal"
import iconEdit from "../../images/pages/edit.png"
import iconDelete from "../../images/pages/delete.png"

const PodcastCard = ({
  id,
  image,
  setShowForm,
  setFormValues,
  title,
  data,
  removeItem,
  uri,
  about,
}) => {
  const { device } = useContext(AppContext)
  const { isShowing, toggle } = useModal()

  return (
    <St.PodcastSliderWrappper>
      <ConfirmDialog
        isShowing={isShowing}
        confirmed={removeItem}
        cancelled={toggle}
        message={"item"}
        data={{ id }}
      />

      <St.PodcastSliderImageWrapper>
        <StyledImage
          image={image ? image.url : ""}
          width={"100%"}
          height={"100%"}
          hover={false}
          imageAlt={title}
          borderRadius={
            getConfigValue(device, "box.podcasts.image.borderRadius") + "px"
          }
        />
      </St.PodcastSliderImageWrapper>
      <St.PodcastSliderInfoWrapper>
        <St.PodcastsSliderTitle style={{ textAlign: "center" }}>
          {title}
        </St.PodcastsSliderTitle>
        <St.OperationWrapper>
          <St.OperationIcon
            src={iconEdit}
            onClick={() => {
              setShowForm({
                show: true,
                edit: true,
              })
              setFormValues(data)
            }}
          />
          <St.OperationIcon
            src={iconDelete}
            onClick={() => {
              toggle()
            }}
          />
        </St.OperationWrapper>
      </St.PodcastSliderInfoWrapper>
    </St.PodcastSliderWrappper>
  )
}

export default PodcastCard
