import React, { useContext, useState } from "react"
import { QUERY_ALL_STATION_SHOWS } from "../../queries/page/show"
import { AppContext } from "../../contexts/AppContext"
import { useMutation, useQuery } from "@apollo/client"
import auth from "../../auth/auth"
import { NotificationManager } from "react-notifications"
import {MEDIA_TYPE_AUDIO, getConfigValue} from '../../constants/props'
import {TAB, LANDSCAPE, MOBILE, DEVICE_MOBILE, } from '../../constants/breakpoints'

import {
  MUTATION_DELETE_SHOW,
  MUTATION_DELETE_EPISODE,
} from "../../queries/page/show"

import UserShowCard from './UserShowCard'
import SliderContainer from '../SliderContainer'

const SliderWrapper = props => {
  const [deleteShow] = useMutation(MUTATION_DELETE_SHOW)
  const [deleteEpisodeMutation] = useMutation(MUTATION_DELETE_EPISODE)

  const deleteEpisodes = async episodeIDs => {
    if (episodeIDs && episodeIDs.length) {
      for (let episodeID of episodeIDs) {
        await deleteEpisodeMutation({
          variables: {
            input: {
              where: {
                id: episodeID,
              },
            },
          },
          context: {
            headers: {
              Authorization: "Bearer " + auth.getToken(),
            },
          },
        })
          .then(deleteEpisodeRes => {
            return true
          })
          .catch(() => {
            return true
          })
      }
    }
  }

  const removeItem = data => {
    return deleteShow({
      variables: {
        input: {
          where: {
            id: data.id,
          },
        },
      },
      context: {
        headers: {
          Authorization: "Bearer " + auth.getToken(),
        },
      },
    })
      .then(deleteShowResponse => {
        if (data && data.episodes && data.episodes.length) {
          deleteEpisodes(data.episodes.map(e => e.id)).then(() => {
            NotificationManager.success("Show removed...")
            // returnn true so that confirmation dialog closes
            props.setForceFetch(true)
            return true
          })
        } else {
          NotificationManager.success("Show removed...")
          // returnn true so that confirmation dialog closes
          props.setForceFetch(true)
          return true
        }
      })
      .catch(() => {
        NotificationManager.error("Operation failed...")
        // returnn true so that confirmation dialog closes
        return true
      })
  }

  let { device } = useContext(AppContext)
  let slidesToShow = getConfigValue(device, "podcasts.slider.slides")

  const [state, setState] = useState({
    showLeftArrow: false,
    showRightArrow: props.data.length - slidesToShow <= 0 ? false : true,
  })

  let settings = {
    infinite: false,
    speed: 150,
    slidesToShow,
    slidesToScroll: 1,
    centerMode: false,
    variableWidth: false,
    lazyLoad: false,
    arrows: false,
    responsive: [
      {
        breakpoint: TAB.minWidth,
        settings: {
          slidesToShow: LANDSCAPE.podcasts.slider.slides,
        },
      },
      {
        breakpoint: LANDSCAPE.minWidth,
        settings: {
          slidesToShow: MOBILE.podcasts.slider.slides,
          initialSlide: props.data.length > 0 ? 1 : 0,
          className: "sibling-visible", // would make overflow visible and hence would allow displaying parts of siblings
        },
      },
    ],
    afterChange: i => {
      setState({
        ...state,
        showLeftArrow: i > 0 ? true : false,
        showRightArrow: props.data.length - slidesToShow - i > 0 ? true : false,
      })
    },
  }
  return props.data.length > 0 ? (
    <SliderContainer
      addPadding={
        device === DEVICE_MOBILE &&
        props.data.length > MOBILE.podcasts.slider.slides
          ? true
          : false
      }
      settings={settings}
      showLeftArrow={state.showLeftArrow}
      showRightArrow={state.showRightArrow}
      type={MEDIA_TYPE_AUDIO}
    >
      {props.data.map((item, i) => (
        <UserShowCard
          key={i}
          {...item}
          data={item}
          setShowForm={props.setShowForm}
          setFormValues={props.setFormValues}
          removeItem={removeItem}
          hover={false}
        />
      ))}
    </SliderContainer>
  ) : null
}

// component that shows user audio list
const ShowsList = props => {
  let { userState } = useContext(AppContext)

  // use fetchPolicy = 'network-only' to force fetch
  const { data, loading } = useQuery(
    QUERY_ALL_STATION_SHOWS,
    {
      variables: {
        userID: userState.id,
      },
      fetchPolicy: props.forceFetch ? "network-only" : undefined,
    }
  )

  if (props.forceFetch) {
    // if force fetched in previous attempt reset flag
    props.setForceFetch(false)
  }

  return !loading ? (
    <React.Fragment>
      <SliderWrapper
        data={data && data.shows && data.shows.length ? data.shows : []}
        setShowForm={props.setShowForm}
        setFormValues={props.setFormValues}
        setForceFetch={props.setForceFetch}
      />
    </React.Fragment>
  ) : (
    <div>Loading...</div>
  )
}

export default ShowsList
