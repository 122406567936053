import React, { useContext, useState } from "react"
import * as St from "../Styled"
import { AppContext } from "../../contexts/AppContext"
import ShowsForm from "./showsform"
import ShowsList from "./showslist"

const ContentStationShows = props => {
  let { device, userState } = useContext(AppContext)
  const [showForm, setShowForm] = useState({
    show: false,
    edit: false,
  })
  const [formValues, setFormValues] = useState({
    id: "",
    title: "",
    about: "",
    preview: "",
  })
  const [forceFetch, setForceFetch] = useState(false)
  return (
    <St.ContentWrapper>
      <St.ContentSection>
        <St.AdWrapper>
          <St.LeftAdSection />
        </St.AdWrapper>

        <St.CenterContentWrapper>
          <St.DataTopHeadingWrapper>
            <St.DataTopHeading ismobile={device === "mobile"}>
              {showForm.show
                ? showForm.edit
                  ? `Update Show`
                  : `Add Show`
                : `Shows`}
            </St.DataTopHeading>
            <St.TopRightButton ismobile={device === "mobile"}>
              {!showForm.show ? (
                <button
                  type="button"
                  onClick={() => {
                    setShowForm({
                      show: true,
                      edit: false,
                    })
                    setFormValues({})
                  }}
                >
                  Add Shows
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() =>
                    setShowForm({
                      show: false,
                      edit: false,
                    })
                  }
                >
                  Back to List
                </button>
              )}
            </St.TopRightButton>
          </St.DataTopHeadingWrapper>

          <St.CenterContent>
            {showForm.show ? (
              <ShowsForm
                device={device}
                setShowForm={setShowForm}
                formValues={formValues}
                setForceFetch={setForceFetch}
                isEdit={showForm.edit}
              />
            ) : (
              userState.id && (
                <ShowsList
                  presenters={props.presenters}
                  {...{
                    setShowForm,
                    setFormValues,
                    setForceFetch,
                    forceFetch,
                  }}
                />
              )
            )}
          </St.CenterContent>

          <St.BottomAdSection />
        </St.CenterContentWrapper>
        <St.AdWrapper>
          <St.RightAdSection />
        </St.AdWrapper>
      </St.ContentSection>
    </St.ContentWrapper>
  )
}

export default ContentStationShows
