import React from "react"
import styled from "styled-components"
import {
  MOBILE,
  LANDSCAPE,
  TAB,
  MEDIUM,
  LARGE,
} from "../../constants/breakpoints"

const ProgressSection = styled.div`
  max-width: 100%;
  margin: 10px 0px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  justify-content: flex-start;
`
const ProgressTitle = styled.h4`
  color:#fff;
  display:flex;
  justify-content: center;
  width: 100%;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 16px;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    font-size: 18px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: 20px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 22px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    font-size: 24px;
  }
`
const ProgressBar = styled.div`
  min-height:10px; 
  display:flex;
  font-size:10px; 
  padding: 4px;
  text-align:center;
  border-radius: 10px; 
  background: linear-gradient(to left, #018801, #01880136) no-repeat; 
  width: ${props => (props.progress ? props.progress : "0")}%;
  justify-content: center;
`

const ProgressContent = props => {

  return (
    <>
      <ProgressSection>
        <ProgressTitle>{props.title}</ProgressTitle>
        {props.progress > 0 && <ProgressBar progress={props.progress}>
          {props.progress > 0 ? props.progress + "%" : null}
        </ProgressBar>}
      </ProgressSection>
    </>
  )
}

export default ProgressContent
