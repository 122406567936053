import React, { useContext, useState } from "react"
import { useForm, useFieldArray } from "react-hook-form"
import * as St from "../Styled"
import { NotificationManager } from "react-notifications"
import { AppContext } from "../../contexts/AppContext"
import { useMutation, useQuery } from "@apollo/client"
import { QUERY_ALL_PRESENTER_OPTIONS } from "../../queries/page/presenter"
import {
  ADD_STATION_SHOW,
  MUTATION_UPDATE_SHOW,
  MUTATION_ADD_EPISODE,
  MUTATION_DELETE_EPISODE,
} from "../../queries/page/show"
import axios from "axios"
import auth from "../../auth/auth"
import { awsStationShowsPath, getEpisodeDayNumber } from "../../constants/props"
import useButtonLoader from "../../hooks/useButtonLoader"
import objectPath from "object-path"
import ProgressContent from "../ProgressBar"
import momentTZ from "moment-timezone"

const ShowsForm = props => {
  let { device, userState, countries } = useContext(AppContext)
  const { handleSubmit, register, errors, reset, control } = useForm({
    defaultValues: props.formValues,
  })

  const [addShow] = useMutation(ADD_STATION_SHOW)
  const [updateShow] = useMutation(MUTATION_UPDATE_SHOW)
  const [addEpisodeMutation] = useMutation(MUTATION_ADD_EPISODE)
  const [deleteEpisodeMutation] = useMutation(MUTATION_DELETE_EPISODE)
  const [coverImage, setCoverImage] = useState({ preview: "", raw: "" })
  const [progressOfImage, setProgressOfImage] = useState({
    count: 0,
    title: "",
  })
  const handleChange = e => {
    if (e.target.files.length) {
      setCoverImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
        name: e.target.files[0].name,
      })
    }
  }

  if (props.forceFetch) {
    // if force fetched in previous attempt reset flag
    props.setForceFetch(false)
  }

  const [loaderElement, setLoader] = useButtonLoader(
    props.isEdit ? "Update Show" : "Add Show",
    "Processing..."
  )

  const updateShowData = (data, where) => {
    return updateShow({
      variables: {
        input: {
          data,
          where,
        },
      },
      context: {
        headers: {
          Authorization: "Bearer " + auth.getToken(),
        },
      },
    })
  }

  const showNotification = (msg, success) => {
    success ? NotificationManager.success(msg) : NotificationManager.error(msg)
    setLoader(false)
    if (success) {
      // reset and hide form if success
      reset()
      props.setShowForm({
        show: false,
        edit: false,
      })
      // as data is updated or created force fetch data from server
      props.setForceFetch(true)
    }
  }

  const basePrefix = `${process.env.STRAPI_SERVER_URL}` // SERVER BASE
  const uploadCoverImage = (coverImageFile, id, showTitle) => {
    // cover image of audio file was updated during update operation
    let showPath =
      userState && userState.station && userState.station.title
        ? awsStationShowsPath(userState.station.title, showTitle)
        : ""

    const coverFormData = new FormData()
    coverFormData.append("files", coverImageFile)
    coverFormData.append("path", showPath)
    coverFormData.append("ref", "show")
    coverFormData.append("refId", id)
    coverFormData.append("field", "image")
    return axios.post(`${basePrefix}/upload`, coverFormData, {
      headers: {
        Authorization: "Bearer " + auth.getToken(),
      },
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        setProgressOfImage({
          count: percentCompleted - 0.05 * percentCompleted,
          title: "Uploading " + coverImage.name,
        })
      },
    })
  }

  const deleteEpisodes = async episodeIDs => {
    if (episodeIDs && episodeIDs.length) {
      for (let episodeID of episodeIDs) {
        await deleteEpisodeMutation({
          variables: {
            input: {
              where: {
                id: episodeID,
              },
            },
          },
          context: {
            headers: {
              Authorization: "Bearer " + auth.getToken(),
            },
          },
        })
          .then(deleteEpisodeRes => {
            return true
          })
          .catch(() => {
            return true
          })
      }
    }
  }

  const addEpisodes = async (episodes, id) => {
    // find country timezone

    let stationCountry =
      countries &&
      countries.length > 0 &&
      userState.station &&
      userState.station.country
        ? countries.find(c => c.id === userState.station.country)
        : null

    if (episodes && episodes.length) {
      for (let episode of episodes) {
        /**
         * For finding next episode for a station we will have to perform time comparison of episodes with current time
         * as these are recurring episodes, for comparison we will keep a reference date/time of comparison where
         * date would be in the range of 1/3/2020 (sunday) - 7/3/2020 (monday) based on day that episode would run
         * and time would be kept in UTC so if episode would run on Wednesday 10:00 am on Wednesday (in any country),
         * reference date would be 4/3/2020 10:00 in utc. Then while finding next episode/show we will get current day/date
         * & time in that country and then create same day/date/time date in UTC and then find next episode.
         *
         * There is no special reason to select march as month, we can select any month/week, it is just that 1st march is a sunday.
         *
         */

        var referenceDate = null

        let fromTime = episode.from.split(":")

        if (stationCountry && stationCountry.timezone && fromTime.length === 2) {
          
          var episodeDay = getEpisodeDayNumber(episode.day)
          // reference moment
          var referenceMoment = momentTZ()
            .utc()
            .date(1) // 1st of march
            .month(2) // march
            .year(2020)
            .day(episodeDay)
            .hour(fromTime[0])
            .minute(fromTime[1])
            .second(0)
            .millisecond(0)

          referenceDate = referenceMoment.toDate()
        }

        await addEpisodeMutation({
          variables: {
            input: {
              data: {
                show: id,
                from: episode.from,
                to: episode.to,
                day: episode.day,
                referenceDate: referenceDate,
                station: userState.station ? userState.station.id : null,
              },
            },
          },
          context: {
            headers: {
              Authorization: "Bearer " + auth.getToken(),
            },
          },
        }).then(addEpisodeResponse => {})
      }
    }
  }

  const { fields, append, remove } = useFieldArray({
    control,
    name: "episodes",
  })

  const onSubmit = values => {
    setLoader(true)
    // First upload cover image
    if (values.id) {
      // Audio is updated by user
      setLoader(true)
      // Update basic data in collection first
      updateShowData(
        {
          title: values.title,
          about: values.about,
          presenter:
            values.presenter && values.presenter.id
              ? values.presenter.id
              : null,
          station: userState.stationID,
        },
        {
          id: values.id,
        }
      ).then(() => {
        if (coverImage.raw) {
          uploadCoverImage(coverImage.raw, values.id)
            .then(response => {
              setProgressOfImage({
                count: 100,
                title: "Image Successfully Uploaded",
              })
              if (
                props.formValues.episodes &&
                props.formValues.episodes.length
              ) {
                deleteEpisodes(props.formValues.episodes.map(e => e.id)).then(
                  () => {
                    if (values.episodes && values.episodes.length) {
                      addEpisodes(values.episodes, values.id).then(response => {
                        showNotification("Show data changed ...", true)
                      })
                    } else {
                      showNotification("Show data changed ...", true)
                    }
                  }
                )
              } else if (values.episodes && values.episodes.length) {
                addEpisodes(values.episodes, values.id).then(response => {
                  showNotification("Show data changed ...", true)
                })
              } else {
                showNotification("Show data changed ...", true)
              }
            })
            .catch(() => {
              showNotification("Cover image upload failed ...", false)
            })
        } else {
          // props.formValues.episodes would contain all the existing episodes, which we will delete and add new one
          if (props.formValues.episodes && props.formValues.episodes.length) {
            deleteEpisodes(props.formValues.episodes.map(e => e.id)).then(
              () => {
                if (values.episodes && values.episodes.length) {
                  addEpisodes(values.episodes, values.id).then(response => {
                    showNotification("Show data changed ...", true)
                  })
                } else {
                  showNotification("Show data changed ...", true)
                }
              }
            )
          } else if (values.episodes && values.episodes.length) {
            addEpisodes(values.episodes, values.id).then(response => {
              showNotification("Show data changed ...", true)
            })
          } else {
            showNotification("Show data changed ...", true)
          }
        }
      })
    } else {
      addShow({
        variables: {
          input: {
            data: {
              title: values.title,
              about: values.about,
              presenter:
                values.presenter && values.presenter.id
                  ? values.presenter.id
                  : undefined,
              station: userState.stationID,
              owner: userState.id,
            },
          },
        },
        context: {
          headers: {
            Authorization: "Bearer " + auth.getToken(),
          },
        },
      })
        .then(showData => {
          if (
            showData.data &&
            showData.data.createShow &&
            showData.data.createShow.show &&
            showData.data.createShow.show.id
          ) {
            if (coverImage.raw) {
              uploadCoverImage(
                coverImage.raw,
                showData.data.createShow.show.id,
                values.title
              )
                .then(response => {
                  setProgressOfImage({
                    count: 100,
                    title: "Image Successfully Uploaded",
                  })
                  addEpisodes(
                    values.episodes,
                    showData.data.createShow.show.id
                  ).then(response => {
                    showNotification("Show Added...", true)
                  })
                })
                .catch(() => {
                  showNotification("Cover image upload failed...", false)
                })
            } else {
              addEpisodes(
                values.episodes,
                showData.data.createShow.show.id
              ).then(response => {
                showNotification("Show Added...", true)
              })
            }
          }
        })
        .catch(() => {
          showNotification("Show addition failed...", false)
        })
    }
  }

  const formValues = props.formValues
  let coverImageURL =
    formValues && formValues.image && formValues.image.url
      ? !formValues.image.url.startsWith("http")
        ? basePrefix + formValues.image.url
        : formValues.image.url
      : undefined

  return (
    <St.Form
      ismobile={device === "mobile"}
      onSubmit={handleSubmit(onSubmit)}
      enctype="multipart/form-data"
    >
      <div>
        {formValues && formValues.id && (
          <St.FormInput
            name="id"
            type="hidden"
            ref={register({
              required: "Required",
            })}
          />
        )}
        <St.Label>Title</St.Label>
        <St.FormInput
          name="title"
          aria-invalid={errors.title ? "true" : "false"}
          aria-describedby="titleError"
          ref={register({
            required: "Please add title for this show",
          })}
        />
        {errors.title && <St.ErrorText>{errors.title.message}</St.ErrorText>}
      </div>
      <div>
        <St.Label>Cover Image (Recommended Size: 250 X 250 pixels)</St.Label>
        <St.FileTypeLabel htmlFor="upload-cover">
          {coverImage.preview ? (
            <St.Image src={coverImage.preview} alt="dummy"></St.Image>
          ) : coverImageURL ? (
            <St.Image src={coverImageURL} alt="dummy"></St.Image>
          ) : (
            <>Click here to Upload Cover Image</>
          )}
        </St.FileTypeLabel>
        <St.FormInputFile
          id="upload-cover"
          name="coverImage"
          aria-invalid={errors.coverImage ? "true" : "false"}
          aria-describedby="coverImageError"
          accept="image/png, image/jpeg"
          type="file"
          onChange={handleChange}
          ref={
            !coverImageURL
              ? register({
                  required: "Please select a cover image for this show",
                })
              : undefined
          }
        />
        {errors.coverImage && (
          <St.ErrorText>{errors.coverImage.message}</St.ErrorText>
        )}
      </div>
      <div>
        <St.Label>Presenter</St.Label>
        <St.FormSelect
          name="presenter.id"
          aria-invalid={errors.presenter ? "true" : "false"}
          aria-describedby="presenterError"
          ref={register()}
        >
          <option key={"empty"} value="">
            -- Select presenter --
          </option>
          {props.presenters &&
            props.presenters.length &&
            props.presenters.map((presenter, i) => (
              <option key={i} value={presenter.id}>
                {presenter.title}
              </option>
            ))}
        </St.FormSelect>
        {errors.presenter && errors.presenter.id && (
          <St.ErrorText>{errors.presenter.id.message}</St.ErrorText>
        )}
      </div>

      <div>
        <St.Label>About</St.Label>
        <St.FormTextarea
          name="about"
          aria-invalid={errors.about ? "true" : "false"}
          aria-describedby="aboutError"
          ref={register({
            required: "Please add something about this show",
          })}
        />
        {errors.about && <St.ErrorText>{errors.about.message}</St.ErrorText>}
      </div>
      <div>
        {fields.map((item, index) => (
          <St.FieldArraySection key={item.id}>
            <div>
              <St.EpisodeLabel>Day</St.EpisodeLabel>
              <St.FormSelect
                name={`episodes[${index}].day`}
                ref={register({
                  required: "Please select day when this show would run",
                })}
              >
                <option value="">-- Select Day of Episode --</option>
                <option value="Monday"> Monday </option>
                <option value="Tuesday"> Tuesday </option>
                <option value="Wednesday"> Wednesday </option>
                <option value="Thursday"> Thursday </option>
                <option value="Friday"> Friday </option>
                <option value="Saturday"> Saturday </option>
                <option value="Sunday"> Sunday </option>
              </St.FormSelect>
              {objectPath.get(errors, `episodes.${index}.day`) && (
                <St.ErrorText>
                  {objectPath.get(errors, `episodes.${index}.day`).message}
                </St.ErrorText>
              )}
            </div>
            <div>
              <St.EpisodeLabel>To</St.EpisodeLabel>
              <St.FormSelect
                name={`episodes[${index}].from`}
                ref={register({
                  required: "Please select time when this show would start",
                })}
              >
                <option value=""> -- Select Show Start Time -- </option>
                <option value="00:00"> 00:00 </option>
                <option value="00:30"> 00:30 </option>
                <option value="01:00"> 01:00 </option>
                <option value="01:30"> 01:30 </option>
                <option value="02:00"> 02:00 </option>
                <option value="02:30"> 02:30 </option>
                <option value="03:00"> 03:00 </option>
                <option value="03:30"> 03:30 </option>
                <option value="04:00"> 04:00 </option>
                <option value="04:30"> 04:30 </option>
                <option value="05:00"> 05:00 </option>
                <option value="05:30"> 05:30 </option>
                <option value="06:00"> 06:00 </option>
                <option value="06:30"> 06:30 </option>
                <option value="07:00"> 07:00 </option>
                <option value="07:30"> 07:30 </option>
                <option value="08:00"> 08:00 </option>
                <option value="08:30"> 08:30 </option>
                <option value="09:00"> 09:00 </option>
                <option value="09:30"> 09:30 </option>
                <option value="10:00"> 10:00 </option>
                <option value="10:30"> 10:30 </option>
                <option value="11:00"> 11:00 </option>
                <option value="11:30"> 11:30 </option>
                <option value="12:00"> 12:00 </option>
                <option value="12:30"> 12:30 </option>
                <option value="13:00"> 13:00 </option>
                <option value="13:30"> 13:30 </option>
                <option value="14:00"> 14:00 </option>
                <option value="14:30"> 14:30 </option>
                <option value="15:00"> 15:00 </option>
                <option value="15:30"> 15:30 </option>
                <option value="16:00"> 16:00 </option>
                <option value="16:30"> 16:30 </option>
                <option value="17:00"> 17:00 </option>
                <option value="17:30"> 17:30 </option>
                <option value="18:00"> 18:00 </option>
                <option value="18:30"> 18:30 </option>
                <option value="19:00"> 19:00 </option>
                <option value="19:30"> 19:30 </option>
                <option value="20:00"> 20:00 </option>
                <option value="20:30"> 20:30 </option>
                <option value="21:00"> 21:00 </option>
                <option value="21:30"> 21:30 </option>
                <option value="22:00"> 22:00 </option>
                <option value="22:30"> 22:30 </option>
                <option value="23:00"> 23:00 </option>
                <option value="23:30"> 23:30 </option>
                <option value="23:59"> 23:59 </option>
              </St.FormSelect>
              {objectPath.get(errors, `episodes.${index}.from`) && (
                <St.ErrorText>
                  {objectPath.get(errors, `episodes.${index}.from`).message}
                </St.ErrorText>
              )}
            </div>
            <div>
              <St.EpisodeLabel>To</St.EpisodeLabel>
              <St.FormSelect
                name={`episodes[${index}].to`}
                ref={register({
                  required: "Please select time when this show would end",
                })}
              >
                <option value=""> -- Select Show End Time -- </option>
                <option value="00:00"> 00:00 </option>
                <option value="00:30"> 00:30 </option>
                <option value="01:00"> 01:00 </option>
                <option value="01:30"> 01:30 </option>
                <option value="02:00"> 02:00 </option>
                <option value="02:30"> 02:30 </option>
                <option value="03:00"> 03:00 </option>
                <option value="03:30"> 03:30 </option>
                <option value="04:00"> 04:00 </option>
                <option value="04:30"> 04:30 </option>
                <option value="05:00"> 05:00 </option>
                <option value="05:30"> 05:30 </option>
                <option value="06:00"> 06:00 </option>
                <option value="06:30"> 06:30 </option>
                <option value="07:00"> 07:00 </option>
                <option value="07:30"> 07:30 </option>
                <option value="08:00"> 08:00 </option>
                <option value="08:30"> 08:30 </option>
                <option value="09:00"> 09:00 </option>
                <option value="09:30"> 09:30 </option>
                <option value="10:00"> 10:00 </option>
                <option value="10:30"> 10:30 </option>
                <option value="11:00"> 11:00 </option>
                <option value="11:30"> 11:30 </option>
                <option value="12:00"> 12:00 </option>
                <option value="12:30"> 12:30 </option>
                <option value="13:00"> 13:00 </option>
                <option value="13:30"> 13:30 </option>
                <option value="14:00"> 14:00 </option>
                <option value="14:30"> 14:30 </option>
                <option value="15:00"> 15:00 </option>
                <option value="15:30"> 15:30 </option>
                <option value="16:00"> 16:00 </option>
                <option value="16:30"> 16:30 </option>
                <option value="17:00"> 17:00 </option>
                <option value="17:30"> 17:30 </option>
                <option value="18:00"> 18:00 </option>
                <option value="18:30"> 18:30 </option>
                <option value="19:00"> 19:00 </option>
                <option value="19:30"> 19:30 </option>
                <option value="20:00"> 20:00 </option>
                <option value="20:30"> 20:30 </option>
                <option value="21:00"> 21:00 </option>
                <option value="21:30"> 21:30 </option>
                <option value="22:00"> 22:00 </option>
                <option value="22:30"> 22:30 </option>
                <option value="23:00"> 23:00 </option>
                <option value="23:30"> 23:30 </option>
                <option value="23:59"> 23:59 </option>
              </St.FormSelect>
              {objectPath.get(errors, `episodes.${index}.to`) && (
                <St.ErrorText>
                  {objectPath.get(errors, `episodes.${index}.to`).message}
                </St.ErrorText>
              )}
            </div>
            <St.FieldArrayDeleteButtonContainer>
              <button type="button" onClick={() => remove(index)}>
                Delete
              </button>
            </St.FieldArrayDeleteButtonContainer>
          </St.FieldArraySection>
        ))}
        <St.FieldArrayAddButtonContainer>
          <button type="button" onClick={() => append({ name: "test" })}>
            Add Episode
          </button>
        </St.FieldArrayAddButtonContainer>
      </div>
      <St.SubmitButton ref={loaderElement}>Submit</St.SubmitButton>
      {progressOfImage.count < 100 && (
        <ProgressContent
          progress={progressOfImage.count}
          title={progressOfImage.title}
        />
      )}
    </St.Form>
  )
}

const ShowFormContainer = props => {
  let { userState } = useContext(AppContext)

  let { data, loading } = useQuery(QUERY_ALL_PRESENTER_OPTIONS, {
    variables: {
      userID: userState.id,
    },
    fetchPolicy: props.forceFetch ? "network-only" : undefined,
  })

  // do not render form until presenter loads
  if (loading) return null

  return (
    <ShowsForm
      {...props}
      presenters={
        data && data.presenters && data.presenters.length ? data.presenters : []
      }
    />
  )
}

export default ShowFormContainer
